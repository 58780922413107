<template>
  <div class="error">
    <el-result style="width: 100%" icon="error" title="同步失败">
      <div slot="subTitle" style="line-height: 1.5; color: #999">
        身份同步失败<br />稍后将返回至应用首页
      </div>
    </el-result>
  </div>
</template>

<script>
export default {};
</script>

<style>
.error {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>